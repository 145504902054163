<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
#app {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  background-color: #f6f5f2;
}
maxWidth {
  max-width: 200px !important;
}
.maxWidthLarge {
  max-width: 300px !important;
}
.maxWidthSmall {
  max-width: 120px !important;
}
.maxWidthMedium {
  max-width: 180px !important;
}
.FontSize10px {
  font-size: 10px !important;
}

.FontSize12px {
  font-size: 12px !important;
}

.FontSize14px {
  font-size: 14px !important;
}
.FontSize16px {
  font-size: 16px !important;
}
.FontSize18px {
  font-size: 18px !important;
}

.cursorPointer {
  cursor: pointer !important;
}
.textField.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.dropdownField.v-select .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.CustomizedTableClass table {
  border: 1px solid #f2f2f2 !important;
  border-collapse: collapse !important;
  /* border-radius: 10px !important; */
  overflow: hidden !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  margin: 0 auto !important;
}
.CustomizedTableClass table tr:nth-child(even) {
  background-color: #e5f6f0 !important;
}
.CustomizedTableClass table th {
  position: -webkit-sticky !important;
  top: 0 !important;
  z-index: 0 !important;
  background: #01ab6e !important;
  color: #fff !important;
}
* {
  scrollbar-width: thin !important;
}

.dashed-line {
  height: 1px;
  width: 100%;
  border: 1px dashed rgba(223, 223, 223, 1);
  margin: 0.8rem 0;
}
</style>
